import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76e2440c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "attachment action" }
const _hoisted_5 = ["name"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "preview"
}
const _hoisted_9 = { class: "file" }
const _hoisted_10 = { alt: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.input.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, [
        _createElementVNode("input", {
          type: "file",
          name: _ctx.input.id,
          ref: _ctx.input.id,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args)))
        }, null, 40, _hoisted_5),
        (_ctx.data.image)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Изменить изображение"))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, "Загрузить изображение"))
      ])
    ]),
    (_ctx.data.image)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("img", _hoisted_10, null, 512), [
              [_directive_lazy, _ctx.data.image.src || _ctx.previewFileSrc(_ctx.data.image)]
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}