
import { defineComponent } from "vue-demi";

export default defineComponent({
  emits: ['uploadFile'],
  name: "ModalImage",
  props: {
    input: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    previewFileSrc(file: File) {
      return URL.createObjectURL(file);
    },
    uploadImage() {
      const ref = this.$refs[this.input.id] as HTMLInputElement;
      if (!ref.files) return;
      this.data.image = ref.files[0];
      this.$emit('uploadFile', { input: this.input, ref: this.$refs[this.input.id] });
    },
    deleteImage() {
      this.data.image = null;
    },
  },
});
